/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useState } from "react";
import { Button } from "@blueprintjs/core";
import { handleLinkAllReportMaterials } from "./utils";
import tooltips from "../../tooltips";

function LinkAllJ5MaterialsButton({
  j5Report,
  afterlinking,
  intent,
  text = "Link Sequences to Materials"
}) {
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    const j5ReportId = j5Report.id;
    setLoading(true);
    try {
      const { alreadyLinked } = await handleLinkAllReportMaterials(j5ReportId);
      window.toastr.success("Successfully linked sequences to materials.");
      if (!alreadyLinked && afterlinking) {
        await afterlinking();
      }
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error linking sequences.");
    }
    setLoading(false);
  };

  return (
    <Button
      data-tip={tooltips.linkJ5MaterialsHelper}
      intent={intent}
      loading={loading}
      onClick={onSubmit}
      text={text}
    />
  );
}

export default LinkAllJ5MaterialsButton;
