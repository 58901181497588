/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";
import { taggedItemFragment } from "../../../../tg-iso-shared/src/fragments/taggedItemFragment";

export const functionProteinRecordViewSubUnitsFragment = gql`
  fragment functionProteinRecordViewSubUnitsFragment on functionalProteinUnit {
    id
    molecularWeight
    proteinSubUnits {
      id
      index
      aminoAcidSequence {
        id
        name
        proteinSequence
        commonId
        size
        molecularWeight
        taggedItems {
          ...taggedItemFragment
        }
        extinctionCoefficient
      }
    }
  }
  ${taggedItemFragment}
`;

export default gql`
  fragment functionalProteinUnitRecordFragment on functionalProteinUnit {
    id
    name
    user {
      id
      username
    }
    ...functionProteinRecordViewSubUnitsFragment
    materialFpus {
      id
      polynucleotideMaterial {
        id
        name
        taggedItems {
          ...taggedItemFragment
        }
        polynucleotideMaterialSequence {
          id
          size
        }
      }
    }
    fpuFiles {
      id
      encoding
      mimetype
      originalname
      path
      size
    }
    cofactor
    commonId
    designId
    externalLink
    molecularWeight
    extinctionCoefficient
    extinctionCoefficientUnit {
      code
      name
    }
    ger
    ner
    pdbId
    createdAt
    updatedAt
  }
  ${functionProteinRecordViewSubUnitsFragment}
`;
