/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { workflowIcon } from "@teselagen/ui";
import { materialsMenu, inventoryMenu, dataMenu } from "./headerCategories";
import { EvolveConfig } from "../../src-evolve/configs/config";
import createMenuFromModelNamesArray from "./createMenuFromModelNamesArray";
let menuItems = [];
menuItems = menuItems.concat([
  {
    name: "Home",
    icon: "home",
    navTo: "/",
    activeOn: ["/"]
  },
  {
    name: "Notebook",
    navTo: "/eln",
    icon: "book",
    activeOn: ["/eln"]
  },
  {
    name: "Molecular Biology",
    menuDescription:
      "Designs are the starting point for most projects. Combine molecules and strains to generate Assembly Reports that allow you to create more complex constructs.",
    icon: "layout-linear",
    submenu: [
      "design",
      "j5Report",
      "-",
      "CRISPRDesigns",
      "guideRnaScorePredictionRuns",
      "alignment"
    ]
  },
  {
    name: "Advanced Mol-Bio",
    disabled: window.frontEndConfig.disabledToolkits.advancedMolecularBiology,
    menuDescription:
      "The Advanced Molecular Biology toolkit allows you to design constructs using the hierarchical design editor. It also enables additional Assembly Methods, Alignments, and Design Rulesets/Schematics",
    icon: "layout-hierarchy",
    submenu: [
      ...(window.frontEndConfig.hiddenFeaturesConfig.designTemplates
        ? []
        : [{ text: "Design Templates", navTo: "/design-templates" }]),
      { text: "Schematics", navTo: "/schematics" },
      { text: "Design Rulesets", navTo: "/design-rulesets" }
    ]
  },
  {
    name: "Molecules",
    icon: "graph",
    menuDescription:
      "Molecules are theoretical entities that are used in the design of constructs. They can be linked to physical inventory items via Materials. Tags and Import Collections also live in this section",
    submenu: [
      "dnaSequence",
      "sequenceFeatures",
      "part",
      "partset",
      "codingDNASequence",
      "oligo",
      "-",
      "guideRNASequence",
      "rnaSequence",
      "rnaPart",
      "-",
      "aminoAcidSequence",
      "regionAnnotation",
      "aminoAcidPart",
      "functionalProteinUnit",
      "-",
      "casEnzyme",
      "-",
      "tag",
      "importCollection"
    ]
  },
  {
    name: "Cells and Genomes",
    icon: "layout-circle",
    submenu: [
      "genomicReferenceSequence",
      "genome",
      "genomicRegion",
      "-",
      "microbialStrain",
      "cellLine"
    ]
  },
  {
    ...materialsMenu,
    submenu: [
      "-",
      "DNAMaterial",
      "RNAMaterial",
      "proteinMaterial",
      "genomicMaterial",
      "microbialMaterial",
      "cellCulture",
      "Reagent"
    ],
    disabled:
      window.frontEndConfig.disabledToolkits.materialsAndInventoryManagement
  },
  {
    ...inventoryMenu,
    submenu: ["sample", "aliquot", "reagentLot", "-", "plate", "tube"],
    disabled:
      window.frontEndConfig.disabledToolkits.materialsAndInventoryManagement
  },
  {
    name: "Workflow Management",
    icon: workflowIcon,
    submenu: [
      "customerRequest",
      "job",
      "workflowDefinition",
      "workflowRun",
      "workQueue",
      "workflowCollection",
      "-",
      "reactionMap",
      "reactionDesign",
      "worklist",
      "-",
      "plateMapGroup",
      "dataTable",
      "dataSet"
    ],
    disabled: window.frontEndConfig.disabledToolkits.workflowManagement
  },
  {
    name: "Tools",
    icon: "briefcase",
    navTo: "/tools"
  },
  // {
  //   name: "Tags and Collections",
  //   icon: "tag",
  //   submenu: []
  // },
  {
    name: "Freezer and Equipment",
    icon: "snowflake",
    submenu: ["location", "equipment", "container", "placementStrategy"],
    disabled: window.frontEndConfig.disabledToolkits.freezerEquipmentManagement
  },
  {
    name: "Lab Automation",
    icon: "automatic-updates",
    submenu: ["report", "placementQueue", "DNASequencingQualityControlReport"],
    disabled: window.frontEndConfig.disabledToolkits.labAutomation
  },
  {
    ...dataMenu,
    submenu: ["dataGrid", "subjects", "assays", "assay-import-jobs"],
    disabled: window.frontEndConfig.disabledToolkits.dataAnalysis
  },
  {
    name: "BioShop",
    icon: "shop",
    submenu: ["newOrder", "Orders"]
  },
  {
    name: "Discover",
    icon: "lab-test",
    submenu: [
      ...(EvolveConfig.evolveViews.models && [
        {
          text: "Predictions",
          navTo: EvolveConfig.constants.VIEW_ALL_PREDICTIVE_MODELS_ROUTE,
          icon: "predictive-analysis"
        }
      ]),
      ...(EvolveConfig.evolveViews.evolutions && [
        {
          text: "Evolutions",
          navTo: EvolveConfig.constants.VIEW_ALL_EVOLUTIVE_MODELS_ROUTE,
          icon: "social-media"
        }
      ]),
      ...(EvolveConfig.evolveViews.generations && [
        {
          text: "Generations",
          navTo: EvolveConfig.constants.VIEW_ALL_GENERATIVE_MODEL_ROUTE,
          icon: "clean"
        }
      ])
    ],
    disabled: window.frontEndConfig.disabledToolkits.discover
  },
  {
    name: "Tasks",
    icon: "history",
    submenu: ["microservice-tasks"]
  }
]);
menuItems.forEach(topLevelMenuItem => {
  if (topLevelMenuItem.submenu) {
    topLevelMenuItem.submenu = createMenuFromModelNamesArray(
      topLevelMenuItem.submenu
    );
  }
});
menuItems = filterActiveMenuItems(menuItems);

export { menuItems };

function filterActiveMenuItems(menuItems = []) {
  const disabledMenuItems = {};

  if (window.frontEndConfig.hideTaskMenu) {
    disabledMenuItems["Tasks"] = true;
  }

  return menuItems.filter(({ name }) => {
    return !disabledMenuItems[name];
  });
}
