/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { filter, isNil, keyBy, pick } from "lodash";
import {
  externalRecordIdentifierModelsNoUpdate,
  externalReferenceKeys
} from "../../constants";
import { isoContext } from "@teselagen/utils";
import { getModelFromSubtype } from "../utils/getModelFromSubtype";
import modelNameToReadableName from "../utils/modelNameToReadableName";
import { modelToExportFragment } from "./modelToExportFragment-conversionFn";
import getUpsertHandlers from "./upsert";
import mergeUpdateRecord from "./upsert/mergeUpdateRecord";
import {
  cleanExternalRecord,
  handleExternalReference,
  validateRecords
} from "./utils";

export const getRecordsForCreate = async (
  { records, subtype, allowBpUpdates },
  ctx = isoContext
) => {
  const { safeQuery } = ctx;
  const model = getModelFromSubtype(subtype);
  const { convertDbModelToUserFacing, fragment } = modelToExportFragment({
    subtype
  });

  // We are using external reference ids to determine
  // if the records are duplicated. This comes from NodeRed Import Hooks,
  // which they were initially implemented thinking about importing records from
  // external databases.
  // Regardless, I think it's fine to keep it like this, since each handler
  // implements its own deduplication logics
  let keyedExistingByExternalId = {};
  handleExternalReference(records);

  const newRecordsWithExternalId = filter(
    records,
    r => !isNil(r.externalReferenceId)
  );
  if (newRecordsWithExternalId.length) {
    const existingRecords = await safeQuery(fragment, {
      variables: {
        filter: {
          externalReferenceId: newRecordsWithExternalId.map(e =>
            e.externalReferenceId.toString()
          ),
          externalReferenceSystem:
            newRecordsWithExternalId[0].externalReferenceSystem,
          externalReferenceType:
            newRecordsWithExternalId[0].externalReferenceType
        }
      }
    });
    keyedExistingByExternalId = keyBy(
      existingRecords,
      r => r.externalReferenceId
    );
  }

  const updateRecords = records.filter(
    r => keyedExistingByExternalId[r.externalReferenceId]
  );
  const createRecords = records.filter(
    r => !keyedExistingByExternalId[r.externalReferenceId]
  );

  const [invalidUpdates, validUpdates] = validateRecords(updateRecords, {
    subtype,
    isUpdate: true,
    allowBpUpdates,
    doNotRequireExternalFields: true
  });
  const [invalidCreates, validCreates] = validateRecords(createRecords, {
    subtype,
    allowBpUpdates,
    doNotRequireExternalFields: true
  });
  const validRecords = validUpdates.concat(validCreates);
  const invalidRecords = invalidUpdates.concat(invalidCreates);

  const duplicatedOnExternalIdRecords = [];
  const nonDuplicateRecords = [];

  for (const record of validRecords) {
    const existingRecord =
      keyedExistingByExternalId[record.externalReferenceId];
    
    // Checks for existing records that already have the same externalReferenceId
    if (existingRecord) {
      // the duplicate records will have the id of the existing record in the database
      record.id = existingRecord.id;
      // TODO if there is a match here we need to transform and attempt to do an update instead

      const __oldRecord = {
        ...convertDbModelToUserFacing(existingRecord),
        ...pick(existingRecord, externalReferenceKeys),
        id: existingRecord.id
      };

      let cleanedRecord = record;
      if (!externalRecordIdentifierModelsNoUpdate.includes(model)) {
        cleanedRecord = cleanExternalRecord(record, subtype, true, {
          allowBpUpdates
        });
      }
      // do we need to revalidate here? and show error/ not let them continue with records

      cleanedRecord = {
        ...cleanedRecord,
        __oldRecord,
        __newRecord: mergeUpdateRecord({
          updateRecord: cleanedRecord,
          originalRecord: __oldRecord
        })
      };
      
      duplicatedOnExternalIdRecords.push(cleanedRecord);
    } else {
      const cleanedRecord = cleanExternalRecord(record, subtype);

      // These are supposed to be CREATE records,
      // thus no ID can be provided.
      if (!isNil(cleanedRecord.id)) {
        Object.assign(cleanedRecord, {
          __importFailed: `Attempted to create a ${modelNameToReadableName(
            model
          )} record with existing ID=${cleanedRecord.id}.`
        });
      }
      
      nonDuplicateRecords.push(cleanedRecord);
    }
  }

  return { nonDuplicateRecords, duplicatedOnExternalIdRecords, invalidRecords };
};

export const createModel = async (
  { model, records, allowBpUpdates = false },
  ctx = isoContext
) => {
  const upsertHandlers = getUpsertHandlers(ctx);
  const handler = upsertHandlers[model];
  handleExternalReference(records);

  const {
    nonDuplicateRecords: recordsToImport,
    invalidRecords,
    duplicatedOnExternalIdRecords // TODO: inform the user about duplicate records maybe.
  } = await getRecordsForCreate(
    {
      records,
      allowBpUpdates,
      subtype: model
    },
    ctx
  );

  let allRecordsToImport = recordsToImport;
  if (allowBpUpdates) {
    allRecordsToImport = allRecordsToImport.concat(
      duplicatedOnExternalIdRecords
    );
  }

  await handler({
    recordsToImport: allRecordsToImport,
    allowBpUpdates
  });

  const seqTypeName = model === "OLIGO" ? "Oligo" : "DNA seq";

  return [
    ...allRecordsToImport,
    ...invalidRecords,
    ...(allowBpUpdates
      ? []
      : duplicatedOnExternalIdRecords.map(seq => {
          const { __oldRecord, __newRecord, ...duplicateRecord } = seq;
          duplicateRecord.duplicate = true;
          duplicateRecord.__importFailed = `Duplicate ${seqTypeName} '${__oldRecord.name}' detected. Use the PUT method if you want to update it.`;
          return duplicateRecord;
        }))
  ];
};
