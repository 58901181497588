/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { forEach, map, omit, reduce } from "lodash";
import { externalRecordIdentifierModelsNoUpdate } from "../../../constants";
import { fragMap } from "../../crudHandlers/modelToExportFragment-conversionFn";
import { getModelNameFromFragment } from "@teselagen/apollo-methods";

const _importExportSubtypes = {};
if (!fragMap) {
  throw new Error("fragMap is not defined");
}
forEach(fragMap, (f, SUBTYPE) => {
  const model = getModelNameFromFragment(f.fragment);
  if (f.doNotUseAsImportExportSubtype) return;
  _importExportSubtypes[SUBTYPE] = {
    key: SUBTYPE,
    name: SUBTYPE,
    model: model
  };
});

const _updateSubtypes = {
  ...omit(_importExportSubtypes, externalRecordIdentifierModelsNoUpdate),
  DESIGN_PART: {
    key: "DESIGN_PART",
    name: "DESIGN_PART",
    model: "element"
  }
};

export const importExportSubtypes = reduce(
  _importExportSubtypes,
  (acc, v, key) => {
    acc[key] = v.name;
    return acc;
  },
  {}
);

export const updateSubtypes = reduce(
  _updateSubtypes,
  (acc, v, key) => {
    acc[key] = v.name;
    return acc;
  },
  {}
);

const _customInfoSubtypes = {
  ..._importExportSubtypes,
  ASSEMBLY_REPORT: {
    key: "ASSEMBLY_REPORT",
    name: "ASSEMBLY_REPORT",
    model: "j5Report"
  },
  SAMPLE: {
    key: "SAMPLE",
    name: "SAMPLE",
    model: "sample"
  },
  JOB: {
    key: "JOB",
    name: "JOB",
    model: "job"
  },
  ALIQUOT: {
    key: "ALIQUOT",
    name: "ALIQUOT",
    model: "aliquot"
  },
  VENDOR_ORDER: {
    key: "VENDOR_ORDER",
    name: "VENDOR_ORDER",
    model: "vendorOrder"
  },
  PLATE_MAP_GROUPS: {
    key: "PLATE_MAP_GROUPS",
    name: "PLATE_MAP_GROUPS",
    model: "plateMapGroup"
  },
  DESIGN: {
    key: "DESIGN",
    name: "DESIGN",
    model: "design"
  },
  REACTION_MAP: {
    key: "REACTION_MAP",
    name: "REACTION_MAP",
    model: "reactionMap"
  }
};

const _allSubtypes = {
  ..._customInfoSubtypes,
  ..._importExportSubtypes,
  ..._updateSubtypes
};

export const allSubtypesToModel = reduce(
  _allSubtypes,
  (acc, v, key) => {
    acc[key] = v.model;
    return acc;
  },
  {}
);

export const allSubtypesToName = reduce(
  _allSubtypes,
  (acc, v, key) => {
    acc[key] = v.name;
    return acc;
  },
  {}
);

const hasSubtype = (subtypes, model, integrationSubtype) => {
  let toReturn;
  map(subtypes, v => {
    if (integrationSubtype) {
      if (integrationSubtype === v.key) toReturn = v;
    } else {
      if (model === v.model) toReturn = v;
    }
  });
  return toReturn;
};

export const hasIntegration = (model, integrationSubtype) => {
  return hasSubtype(_allSubtypes, model, integrationSubtype);
};

export const hasExternalImportIntegration = (model, integrationSubtype) => {
  return hasSubtype(_importExportSubtypes, model, integrationSubtype);
};

export const customInfoSubtypes = reduce(
  _customInfoSubtypes,
  (acc, v, key) => {
    acc[key] = v.name;
    return acc;
  },
  {}
);

// TODO: DESIGN subtype not yet supported for update hook
export const updateSubtypesArray = Object.values(updateSubtypes).filter(
  subtype => subtype !== "DESIGN"
);
export const importExportSubtypesArray = Object.values(importExportSubtypes);
export const customInfoSubtypesArray = Object.values(customInfoSubtypes);
